import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators'

import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class ApiService {

    /**
     * constructor
     */
    constructor(
        private http: HttpClient
    ) {

    }

    /**
     * url
     */
    public url(): any {
        return environment.apiEndPoint;
    }

    /**
     * key
     */
    public key(): any {
        return environment.apiKey;
    }

    /**
     * headers
     */
    public headers(): any {
        return new HttpHeaders()
            .set('Authorization', 'Bearer ' + this.key())
    }

    /**
     * get
     */
    public get(action: string): Observable<any> {
        return this.http.get(this.url() + action, { 'headers': this.headers() });
    }

    /**
     * post
     */
    public post(action: string, params: object): Observable<any> {
        return this.http.post(this.url() + action, params, { 'headers': this.headers() });
    }

    /**
     * put
     */
    public put(action: string, params: object): Observable<any> {
        return this.http.put(this.url() + action, params, { 'headers': this.headers() });
    }
}