import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./features/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'confirmation',
        loadChildren: () =>
            import('./features/finish/finish.module').then(m => m.FinishModule)
    },
    {
        path: 'paiement',
        loadChildren: () =>
            import('./features/pay/pay.module').then(m => m.PayModule)
    },
    {
        path: 'paiement/:contract',
        loadChildren: () =>
            import('./features/pay/pay.module').then(m => m.PayModule)
    },
    {
        path: 'paiement/:contract/:string',
        loadChildren: () =>
            import('./features/pay/pay.module').then(m => m.PayModule)
    },
    {
        path: 'devis',
        loadChildren: () =>
            import('./features/quote/quote.module').then(m => m.QuoteModule)
    },
    {
        path: '**',
        redirectTo: 'home'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }