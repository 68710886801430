<div id="module-auto" *ngIf="simpleAuto === true">
    <div id="header-auto" class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom box-shadow">
        <div id="header-auto-content">
            <div class="logo">
                <a routerLink="/" class="header-logo">Souscrivez en ligne à votre contrat auto</a>
            </div>
        </div>

        <div id="header-step-auto" class="mt-4 mt-md-0">
            <div class="timeline" [ngClass]="{'active': this.stepAuto == 'car' || this.stepAuto == 'car-finish'}">
                <div class="timeline-picto" [ngClass]="{'right': this.stepAuto == 'car-finish'}">
                    <i class="fas fa-car-side"></i>
                </div>

                <div class="timeline-percent">

                </div>

                <div class="timeline-info">
                    <p>Ma voiture</p>
                </div>
            </div>

            <div class="timeline" [ngClass]="{'active': this.stepAuto == 'user' || this.stepAuto == 'user-middle' || this.stepAuto == 'user-finish'}">
                <div class="timeline-picto" [ngClass]="{'right': this.stepAuto == 'user-finish', 'middle': this.stepAuto == 'user-middle'}">
                    <i class="fas fa-users"></i>
                </div>

                <div class="timeline-percent">
                    
                </div>

                <div class="timeline-info">
                    <p>Mes conducteurs</p>
                </div>
            </div>

            <div class="timeline" [ngClass]="{'active': this.stepAuto == 'contract' || this.stepAuto == 'contract-middle' || this.stepAuto == 'contract-finish'}">
                <div class="timeline-picto" [ngClass]="{'right': this.stepAuto == 'contract-finish', 'middle': this.stepAuto == 'contract-middle'}">
                    <i class="fas fa-file-contract"></i>
                </div>

                <div class="timeline-percent">
                    
                </div>

                <div class="timeline-info">
                    <p>Mon devis</p>
                </div>
            </div>

            <div class="timeline" [ngClass]="{'active': this.stepAuto == 'contractsign' || this.stepAuto == 'contractsign-middle' ||  this.stepAuto == 'contractsign-finish'}">
                <div class="timeline-picto" [ngClass]="{'right': this.stepAuto == 'contractsign-finish', 'middle': this.stepAuto == 'contractsign-middle'}">
                    <i class="fas fa-file-signature"></i>
                </div>

                <div class="timeline-percent">
                    
                </div>

                <div class="timeline-info">
                    <p>Mon contrat</p>
                </div>
            </div>
        </div>
    </div>

    <div id="content-auto">
        <router-outlet></router-outlet>
    </div>
</div>

<div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 bg-white border-bottom box-shadow" *ngIf="simple === false && simpleAuto === false">
    <h5 class="my-0 mr-md-auto font-weight-normal">
        <a routerLink="/" class="header-logo" *ngIf="stepPay == ''"></a>
        <a routerLink="/paiement" class="header-logo" *ngIf="stepPay == 'true'"></a>
    </h5>

    <nav class="my-2 my-md-0 mr-md-3">
        <a class="p-2 text-dark"><i class="fas fa-phone-alt mr-1"></i> 01 84 80 97 38</a>
    </nav>

    <a class="btn btn-outline-primary" routerLink="/" *ngIf="stepPay == ''">Souscrivez en ligne</a>
</div>

<div class="container-global" *ngIf="simple === false && simpleAuto === false">
    <div class="page-content">
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>

    <div class="col-12 need-help" *ngIf="simple === false">
        <div class="container">
            <div class="row">
                <div class="need-help-head col-12">
                    <i class="far fa-question-circle"></i>
                </div>

                <div class="need-help-content col-12">
                    <h2>Vous avez besoin d'aide, une question ?</h2>

                    <p>Nos conseillers sont à votre écoute.</p>

                    <p class="contact-actudata">01 84 80 97 38</p>
                </div>
            </div>
        </div>
    </div>

    <footer class="pt-4 col-12">
        <div class="row">
            <div class="col-3">
                <img class="mb-2 logo-footer" src="./assets/img/logo_footer.png" alt="Actudata" width="60"
                    height="60" />
                <small class="d-block mb-3 text-muted copy-footer">Actudata &copy; 2020</small>
            </div>
            <div class="col-3">
                <h5>Nos Produits</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="text-muted" href="https://actudata.fr/assurance-protection-juridique-360/">Protection
                            Judiciaire 360</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/assurance-auto-standard-et-aggraves/">Assurance
                            Auto</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/sante-flexible/">Mutuelle Santé</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/integrale-hospi/">Indemnités Journalières</a>
                    </li>
                    <li><a class="text-muted" href="https://actudata.fr/integrale-hospi/">Hospitalisation</a></li>
                </ul>
            </div>
            <div class="col-3">
                <h5>A Propos</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="text-muted" href="https://actudata.fr/nos-offres/">Nos Offres</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/reclamations/">Reclamations</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/contact/">Contact</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/mentions-legales/">Mentions Légales</a></li>
                </ul>
            </div>
            <div class="col-3">
                <h5>Nous Contacter</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="text-muted" href="https://actudata.fr/mentions-legales/">01 84 80 97 38</a></li>
                    <li><a class="text-muted" href="https://actudata.fr/mentions-legales/">contact@actudata.fr</a></li>
                    <li><br /> 26 Rue des sablons <br /> 95000 Montmagny</li>
                </ul>
            </div>
        </div>
    </footer>
</div>