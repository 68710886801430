import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule } from '@angular/common/http';

import { MainLayoutComponent } from './layout/main-layout/main-layout.component';

import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        MainLayoutComponent
    ],
    imports: [
        // vendor
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        RouterModule,
        HttpClientModule
    ],
    exports: [
        MainLayoutComponent
    ]
})
export class CoreModule { }