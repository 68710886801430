import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
@Component({
    selector: 'actudata-main-layout',
    templateUrl: './main-layout.component.html'
})
export class MainLayoutComponent implements OnInit {
    public stepPay: string = 'current';
    public stepAuto: string = 'car';
    
    /**
     * simple layout (without header and footer)
     */
    public simple: boolean = false;
    
    public simpleAuto: boolean = false;

    constructor(
        private activateRoute: ActivatedRoute,
        private router: Router
    ) {

    }

    ngOnInit(): void {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                if (e.url.includes('paiement')) {
                    this.stepPay = 'true';
                } else {
                    this.stepPay = '';
                }

                if (e.url.includes('devis/auto')) {
                    this.simple = true;
                    this.simpleAuto = true;
                } else {
                    // else for reset all time
                    this.simple = false;
                    this.simpleAuto = false;
                }
            }
        });
    }
}